<template>
  <div class="week-scheduler--container">
    <div v-if="list.length">
      <div class="row" v-for="(item, index) in list" :key="index">
        <div class="col-11">
          <div class="row">
            <div class="col-6">
              <date-picker v-model="item.daterange" range append-to-body :lang="pickerLang" :format="'DD MMM YYYY'"
                :type="'date'" confirm class="mr-0 form-control-sm" @input="emitComponent">
              </date-picker>
            </div>
            <div class="col-3 mt-1">
              <div class="form-group">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    :placeholder="$t('components.weekScheduler.timeFrom')"
                    v-model="item.time_from"
                    @input="emitComponent"
                    v-mask="'##:##'"
                />
              </div>
            </div>
            <div class="col-3 mt-1">
              <div class="form-group">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    :placeholder="$t('components.weekScheduler.timeTo')"
                    v-model="item.time_to"
                    @input="emitComponent"
                    v-mask="'##:##'"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-1 mt-1">
          <div class="form-group">
            <div @click="deleteItem(index)" class="btn btn-sm btn-danger">
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <small class="text-muted">
      Если необходимо указать круглосуточное время, то ставьте с 00:00 по 24:00.<br>
      Если неободимо указать нерабочий день, то ставьте время с 00:00 по 00:00
    </small>
    <div class="mt-2">
      <div @click="addSchedule" class="btn btn-sm btn-success">
        <i class="fas fa-plus"></i> {{$t('components.printerCustomSchedule.addCustomSchedule')}}
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from "vue2-datepicker";
import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

export default {
  name: "PrinterCustomSchedule",
  data() {
    return {
      list: [],
      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },
    };
  },
  components: {DatePicker},
  props: {
    default: Array,
  },
  computed: {},
  watch: {
    list: function(){
      this.emitComponent();
    },
    default: function(val){
      this.list = [];
      for (let key in val){
        let obj = {};
        obj.time_from = val[key].time_from;
        obj.time_to = val[key].time_to;
        obj.daterange = [
          formatter.phpDateToJSObject(val[key].date_from),
          formatter.phpDateToJSObject(val[key].date_to)
        ];

        this.list.push(obj);
      }
    }
  },
  methods: {
    emitComponent(){
      let result = [];
      
      for(let key in this.list){
        let obj = {};
        obj.time_from = this.list[key].time_from;
        obj.time_to = this.list[key].time_to;
        obj.minute_from = formatter.timeToMinute(this.list[key].time_from);
        obj.minute_to = formatter.timeToMinute(this.list[key].time_to);
        if(this.list[key].daterange.length > 0){
          obj.date_from = formatter.jsObjectToPhp(this.list[key].daterange[0]);
          obj.date_to = formatter.jsObjectToPhp(this.list[key].daterange[1]);
        }else{
          obj.date_from = '';
          obj.date_to = ''
        }
        
        result.push(obj);
      }
      this.$emit('emitComponent', result);
    },
    addSchedule(){
      let obj = {};
      obj.time_from = '';
      obj.time_to = '';
      obj.daterange = [];
      this.list.push(obj);
    },
    deleteItem(idx){
      for(let key in this.list){
        if(key == idx){
          this.list.splice(key, 1);
        }
      }
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">

</style>
