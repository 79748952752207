<template>
  <div class="week-scheduler--container">
    <div v-if="list.length">
      <div class="row" v-for="(item, index) in list" :key="index">
        <div class="col-lg-6">
          <div class="btn-toolbar form-group">
            <button
                type="button"
                class="btn btn-sm"
                @click="toggleWeek(index, 0)"
                :class="{'btn-light': item.weekdays[0] === false, 'btn-info': item.weekdays[0] === true}"
            >
              {{$t('datepicker.weekdaysMin')[1]}}
            </button>
            <button
                type="button"
                class="btn btn-sm"
                @click="toggleWeek(index, 1)"
                :class="{'btn-light': item.weekdays[1] === false, 'btn-info': item.weekdays[1] === true}"
            >
              {{$t('datepicker.weekdaysMin')[2]}}
            </button>
            <button
                type="button"
                class="btn btn-sm"
                @click="toggleWeek(index, 2)"
                :class="{'btn-light': item.weekdays[2] === false, 'btn-info': item.weekdays[2] === true}"
            >
              {{$t('datepicker.weekdaysMin')[3]}}
            </button>
            <button
                type="button"
                class="btn btn-sm"
                @click="toggleWeek(index, 3)"
                :class="{'btn-light': item.weekdays[3] === false, 'btn-info': item.weekdays[3] === true}"
            >
              {{$t('datepicker.weekdaysMin')[4]}}
            </button>
            <button
                type="button"
                class="btn btn-sm"
                @click="toggleWeek(index, 4)"
                :class="{'btn-light': item.weekdays[4] === false, 'btn-info': item.weekdays[4] === true}"
            >
              {{$t('datepicker.weekdaysMin')[5]}}
            </button>
            <button
                type="button"
                class="btn btn-sm"
                @click="toggleWeek(index, 5)"
                :class="{'btn-light': item.weekdays[5] === false, 'btn-info': item.weekdays[5] === true}"
            >
              {{$t('datepicker.weekdaysMin')[6]}}
            </button>
            <button
                type="button"
                class="btn btn-sm"
                @click="toggleWeek(index, 6)"
                :class="{'btn-light': item.weekdays[6] === false, 'btn-info': item.weekdays[6] === true}"
            >
              {{$t('datepicker.weekdaysMin')[0]}}
            </button>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <input
                type="text"
                class="form-control form-control-sm"
                :placeholder="$t('components.weekScheduler.timeFrom')"
                v-model="item.time_from"
                @input="emitComponent"
                v-mask="'##:##'"
            />
          </div>
        </div>
        <div class="col-lg-3">
          <input
              type="text"
              class="form-control form-control-sm"
              :placeholder="$t('components.weekScheduler.timeTo')"
              v-model="item.time_to"
              @input="emitComponent"
              v-mask="'##:##'"
          />
        </div>
      </div>
    </div>
    <div else>
      <div @click="addSchedule" class="btn btn-sm btn-success">
        <i class="fas fa-plus"></i> {{$t('components.weekScheduler.addSchedule')}}
      </div>
      <div v-if="list.length > 1" @click="removeLast" class="btn btn-sm btn-danger ml-2">
        <i class="fas fa-times"></i> {{$t('components.weekScheduler.put')}}
      </div>
    </div>
  </div>
</template>

<script>

import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

export default {
  name: "WeekScheduler",
  data() {
    return {
      list: []
    };
  },
  props: {
    default: Array,
  },
  computed: {},
  watch: {
    list: function(){
      this.emitComponent();
    },
    default: function(val){
      this.list = [];
      for (let key in val){
        let obj = {};
        obj.time_from = val[key].time_from;
        obj.time_to = val[key].time_to;
        let weekdays = [];
        for(let i = 0; i <= 6; i++){
          if(val[key].weekdays.includes(i)){
            weekdays[i] = true;
          }else{
            weekdays[i] = false;
          }
        }
        obj.weekdays = weekdays;
        this.list.push(obj);
      }
    }
  },
  methods: {
    emitComponent(){
      let result = [];
      for(let key in this.list){
        let obj = {};
        obj.time_from = this.list[key].time_from;
        obj.time_to = this.list[key].time_to;
        obj.minute_from = formatter.timeToMinute(this.list[key].time_from);
        obj.minute_to = formatter.timeToMinute(this.list[key].time_to);
        let weekdays = [];
        for(let i = 0; i <= 6; i++){
          if(this.list[key].weekdays[i]){
            weekdays[weekdays.length] = i;
          }
        }
        obj.weekdays = weekdays;
        result.push(obj);
      }
      this.$emit('emitComponent', result);
    },
    addSchedule(){
      let obj = {};
      obj.time_from = '';
      obj.time_to = '';
      obj.weekdays = [false,false,false,false,false,false,false];
      this.list.push(obj);
    },
    removeLast(){
      this.list.splice(this.list.length -1, 1);
    },
    toggleWeek(index, weekNumber){
      for(let key in this.list){
        if(this.list[key].weekdays[weekNumber] && +key !== +index){
          this.$store.dispatch("addNotification", {
            text: this.$t('components.weekScheduler.weekBeenSelected'),
            time: 3,
            color: "danger"
          });
          return false;
        }
      }
      this.$set(this.list[index].weekdays, weekNumber, !this.list[index].weekdays[weekNumber]);
      this.emitComponent();
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">

</style>
